import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";

let stripePromise
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY)
  }
  return stripePromise
}

const query = `
  mutation createPaymentSession($amount: Float){
    createPaymentSession(amount: $amount)
  }
`

const PaymentPage = () => {

  const [amount, setAmount] = useState(null);
  const pay = async event => {
    event.preventDefault()
    const stripe = await getStripe()
    const response = await fetch(process.env.GRAPHQL_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: query,
        variables: { amount }
      }),
    });
    const { data } = await response.json();
    // eslint-disable-next-line no-unused-vars
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { error } = stripe.redirectToCheckout({
      sessionId: data.createPaymentSession
    });
  }

  return (
    <form className="w-screen h-screen flex flex-col justify-center items-center" onSubmit={pay}>
      <div className="w-1/4 mt-1 relative rounded-xl shadow-sm px-4 py-3 border-gray-300 border-2">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          {/* Heroicon name: mail */}
          <svg className="h-8 w-8 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </div>
        <input
          type="number"
          name="amount"
          id="amount"
          onChange={e => setAmount(parseFloat(e.target.value))}
          className="focus:outline-none block w-full pl-10 sm:text-2xl sm:font-semibold border-gray-300 rounded-xl appearance-none" placeholder="Enter amount here" />
      </div>
      <input
        className="mt-4 w-full sm:w-auto flex-none cursor-pointer bg-gray-900 hover:bg-gray-700 text-white text-lg leading-6 font-semibold py-3 px-6 border border-transparent rounded-xl focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-gray-900 focus:outline-none transition-colors duration-200"
        type="submit"
        value="Proceed to Pay"/>
    </form>
  )
}

export default PaymentPage;
